import { createSlice } from "@reduxjs/toolkit";
import { servicesApi } from "./services/services";

const initialState = {
  setup_s: {
    sorted: "null",
    pinned: [],
    range_flag: false,
    range_min: 0,
    range_max: 1000000,
    analogs: {},
    results: {},
    short: {},
  },
  setup_r: {
    sorted: "null",
    pinned: [],
    range_flag: false,
    range_min: 0,
    range_max: 1000000,
    analogs: {},
    results: {},
    short: {},
  },
  params: {
    weight_s: 0.5,
    sq_method_s:
      JSON.parse(sessionStorage.getItem("params"))?.sq_method_s || "1",
    sq_method_r:
      JSON.parse(sessionStorage.getItem("params"))?.sq_method_r || "1",
    wg_method: 1,
    saved_id: null,
  },
  params_calc: {},
  checked: false,
  message: "",
  changed: false,
};

export const setupSlice = createSlice({
  name: "setup",
  initialState,
  reducers: {
    resetSetup: () => initialState,
    setChecked: (state, action) => {
      state.checked = action.payload;
      sessionStorage.setItem("checked", state.checked);
      state.message = "change checked";
    },
    restoreData: (state) => {
      state.setup_s = JSON.parse(sessionStorage.getItem("setup_s"));
      state.setup_r = JSON.parse(sessionStorage.getItem("setup_r"));
      state.params = JSON.parse(sessionStorage.getItem("params"));
      state.message = "restore";
    },
    changeWg_method: (state) => {
      if (state.params.wg_method === 3) {
        state.params.wg_method = 1;
        sessionStorage.setItem("params", JSON.stringify(state.params));
      } else {
        state.params.wg_method = state.params.wg_method + 1;
        sessionStorage.setItem("params", JSON.stringify(state.params));
      }
      state.changed = true;
    },
    changeSq_method: (state, action) => {
      if (state.checked) {
        if (action.payload === "1") {
          state.params.sq_method_r = "1";
          sessionStorage.setItem("params", JSON.stringify(state.params));
        } else {
          state.params.sq_method_r = "2";
          sessionStorage.setItem("params", JSON.stringify(state.params));
        }
      } else {
        if (action.payload === "1") {
          state.params.sq_method_s = "1";
          sessionStorage.setItem("params", JSON.stringify(state.params));
        } else {
          state.params.sq_method_s = "2";
          sessionStorage.setItem("params", JSON.stringify(state.params));
        }
      }
      state.changed = true;
    },
    changeOrderPinned: (state, action) => {
      if (state.checked) {
        state.setup_r.pinned = action.payload;
        sessionStorage.setItem("setup_r", JSON.stringify(state.setup_r));
      } else {
        state.setup_s.pinned = action.payload;
        sessionStorage.setItem("setup_r", JSON.stringify(state.setup_s));
      }
      state.changed = true;
    },
    changePinned: (state, action) => {
      if (state.checked) {
        if (state.setup_r.pinned.includes(action.payload)) {
          state.setup_r.pinned = state.setup_r.pinned.filter(
            (item) => item !== action.payload
          );
          sessionStorage.setItem("setup_r", JSON.stringify(state.setup_r));
        } else {
          state.setup_r.pinned = [...state.setup_r.pinned, action.payload];
          sessionStorage.setItem("setup_r", JSON.stringify(state.setup_r));
        }
      } else {
        if (state.setup_s.pinned.includes(action.payload)) {
          state.setup_s.pinned = state.setup_s.pinned.filter(
            (item) => item !== action.payload
          );
          sessionStorage.setItem("setup_s", JSON.stringify(state.setup_s));
        } else {
          state.setup_s.pinned = [...state.setup_s.pinned, action.payload];
          sessionStorage.setItem("setup_s", JSON.stringify(state.setup_s));
        }
      }
      state.changed = true;
    },
    changeSort: (state, action) => {
      if (state.checked) {
        state.setup_r.sorted = action.payload;
        sessionStorage.setItem("setup_r", JSON.stringify(state.setup_r));
      } else {
        state.setup_s.sorted = action.payload;
        sessionStorage.setItem("setup_s", JSON.stringify(state.setup_s));
      }
      state.changed = true;
    },
    changeWeight: (state, action) => {
      state.params.weight_s = action.payload;
      state.changed = true;
      sessionStorage.setItem("params", JSON.stringify(state.params));
    },
    changeRange_min: (state, action) => {
      if (state.checked) {
        state.setup_r.range_min = action.payload;
        sessionStorage.setItem("setup_r", JSON.stringify(state.setup_r));
      } else {
        state.setup_s.range_min = action.payload;
        sessionStorage.setItem("setup_s", JSON.stringify(state.setup_s));
      }
    },
    changeRange_max: (state, action) => {
      if (state.checked) {
        state.setup_r.range_max = action.payload;
        sessionStorage.setItem("setup_r", JSON.stringify(state.setup_r));
      } else {
        state.setup_s.range_max = action.payload;
        sessionStorage.setItem("setup_s", JSON.stringify(state.setup_s));
      }
    },
    changeRange_flag: (state, action) => {
      if (state.checked) {
        state.setup_r.range_flag = action.payload;
        state.changed = true;
        sessionStorage.setItem("setup_r", JSON.stringify(state.setup_r));
      } else {
        state.setup_s.range_flag = action.payload;
        state.changed = true;
        sessionStorage.setItem("setup_s", JSON.stringify(state.setup_s));
      }
    },
    manualUpdate: (state) => {
      state.changed = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        servicesApi.endpoints.newCalc.matchFulfilled,
        (state, action) => {
          const {
            analogs_s,
            analogs_r,
            short_s,
            short_r,
            results,
            saved_id,
            status,
          } = action.payload;
          state.setup_s.analogs = analogs_s;
          state.setup_r.analogs = analogs_r;
          state.setup_s.results = results.sp;
          state.setup_r.results = results.dp;
          state.setup_s.short = short_s;
          state.setup_r.short = short_r;
          state.params.saved_id = saved_id;
          state.params_calc = status.params_calc;
          sessionStorage.setItem("savedID", saved_id);
          state.changed = false;
        }
      )
      .addMatcher(
        servicesApi.endpoints.updateCalc.matchFulfilled,
        (state, action) => {
          const { analogs_s, analogs_r, short_s, short_r, results, saved_id } =
            action.payload;
          state.setup_s.analogs = analogs_s;
          state.setup_r.analogs = analogs_r;
          state.setup_s.results = results.sp;
          state.setup_r.results = results.dp;
          state.setup_s.short = short_s;
          state.setup_r.short = short_r;
          sessionStorage.setItem("savedID", saved_id);
          state.changed = false;
        }
      )
      .addMatcher(
        servicesApi.endpoints.extendCalc.matchFulfilled,
        (state, action) => {
          const {
            analogs_s,
            analogs_r,
            short_s,
            short_r,
            results,
            saved_id,
            status,
          } = action.payload;
          state.setup_s.analogs = analogs_s;
          state.setup_r.analogs = analogs_r;
          state.setup_s.results = results.sp;
          state.setup_r.results = results.dp;
          state.setup_s.short = short_s;
          state.setup_r.short = short_r;
          state.setup_s.pinned = status.pinned_s;
          state.setup_r.pinned = status.pinned_r;
          sessionStorage.setItem("savedID", saved_id);
          state.changed = false;
        }
      )
      .addMatcher(
        servicesApi.endpoints.oldCalc.matchFulfilled,
        (state, action) => {
          const {
            analogs_s,
            analogs_r,
            short_s,
            short_r,
            results,
            saved_id,
            status,
          } = action.payload;
          state.setup_s.analogs = analogs_s;
          state.setup_r.analogs = analogs_r;
          state.setup_s.results = results.sp;
          state.setup_r.results = results.dp;
          state.setup_s.short = short_s;
          state.setup_r.short = short_r;
          state.params.saved_id = saved_id;
          state.setup_s.pinned = status.pinned_s;
          state.setup_r.pinned = status.pinned_r;
          sessionStorage.setItem("savedID", saved_id);
          state.changed = false;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("rejected"),
        (state, action) => {
          state.error = action.payload.error;
          state.changed = false;
        }
      );
  },
});

export const {
  resetSetup,
  setChecked,
  restoreData,
  changeSq_method,
  changeWg_method,
  changePinned,
  changeOrderPinned,
  changeSort,
  changeWeight,
  changeRange_min,
  changeRange_max,
  changeRange_flag,
  manualUpdate,
} = setupSlice.actions;

export default setupSlice.reducer;
